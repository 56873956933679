@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.light {
  --nextui-background: 100% 0% 100%;
}
:root {
  --nextui-background: 100% 0% 100%;
}

* {
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  overflow-y: scroll;
  @apply flex items-center justify-center;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  background-color: #e7e7e7;
  @apply max-w-[1400px] flex justify-center p-0 box-border mt-0 m-auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-box-select {
  box-shadow: inset 1px 1px 5px 0px #919191;
  border-radius: 4px;
  background: linear-gradient(142deg, #e0e0e0, #fff);
}

main {
  @apply pl-[10px] pr-[10px] pb-[10px] sm:flex sm:flex-col sm:items-start sm:w-full sm:h-auto md:w-[700px] lg:flex lg:flex-row lg:items-start lg:justify-between  lg:h-full lg:w-[700px] lg:pl-0 lg:pr-0 lg:pb-0;
}

.inner-main {
  @apply sm:flex sm:flex-col lg:flex lg:flex-col lg:w-3/4 lg:h-full;
}

section {
  @apply lg:w-full lg:h-auto;
}

label {
  @apply lg:flex lg:flex-col lg:gap-5 lg:text-xs;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.linksdiv {
  @apply lg:flex lg:pt-5 lg:gap-5;
}

.links {
  @apply lg:p-5 lg:bg-[#d53844] lg:border-1 lg:border-solid lg:border-[#d53844] lg:rounded-md lg:text-white lg:no-underline lg:duration-500;
}

.links:hover {
  @apply lg:text-black lg:bg-transparent;
}

.obliczenia {
  @apply grid grid-cols-2 grid-flow-row w-full gap-[2px] items-center justify-center sm:w-full lg:w-full sm:flex sm:flex-col;
}

.topItem {
  @apply flex flex-col gap-4 items-start w-full mt-3 sm:flex sm:flex-row sm:gap-5 sm:justify-between sm:mt-3 sm:w-full md:justify-between lg:flex lg:flex-row lg:gap-6 lg:mt-3 lg:w-full;
}

.topItem-inner-container {
  @apply flex items-center justify-center border border-gray-400 h-60 w-full sm:w-3/5 sm:h-60;
}

.topItemRight {
  @apply flex flex-col w-full sm:flex sm:flex-col sm:w-2/5 sm:h-full md:w-2/5 lg:flex lg:flex-col lg:w-2/5;
}

.topItemRight label select {
  @apply w-1/2 sm:w-full;
}

.itemName {
  @apply uppercase text-[#919191] font-semibold text-2xl;
}

.obliczenia-inputs {
  @apply flex flex-col items-start justify-start h-[5.5rem] w-full sm:flex sm:flex-col sm:items-start sm:h-auto sm:w-full sm:pt-1 lg:flex lg:flex-col lg:items-start lg:justify-start lg:h-auto lg:pt-1;
}

.obliczenia-inputs label {
  @apply w-full h-6 pl-[10px] text-[12px] text-wrap sm:w-full sm:h-[2em] sm:text-nowrap lg:w-full lg:text-nowrap lg:h-[2em] lg:text-[11px];
}

.details {
  @apply flex flex-col h-auto text-[#919191] w-full items-stretch justify-start p-4 sm:flex sm:flex-col sm:h-auto sm:w-full  sm:p-4 lg:flex lg:flex-col lg:h-auto lg:w-full lg:p-4;
}

.info {
  @apply font-semibold text-xs text-[#919191] border-t-3 border-solid border-[#b1b1b1]  w-full pl-2 sm:text-xs sm:border-t-3 sm:border-solid sm:border-[#b1b1b1] sm:pt-5 sm:w-full sm:pl-2 lg:font-semibold lg:text-xs lg:text-[#919191] lg:border-t-3 lg:border-solid lg:border-[#b1b1b1]  lg:w-full lg:pl-2;
}

.weight {
  @apply text-base text-black font-semibold ml-5;
}

.pweight {
  @apply flex items-end w-full h-auto font-semibold sm:flex sm:items-end sm:w-full sm:h-8 text-base text-black;
}

.pweight p {
  @apply w-auto text-left text-nowrap;
}

.redWeight {
  @apply w-full leading-none text-right font-black text-[25px] sm:w-full sm:leading-none sm:text-right sm:text-[32px] lg:text-[28px] text-[#d63744];
}

input,
select {
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 0;
  color: #919191;
  -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  box-shadow: inset 1px 1px 5px 0px #919191;
}

select {
  @apply text-sm sm:text-sm;
}

input {
  @apply w-full rounded-md text-black bg-[#fff] px-4 py-4 sm:w-full sm:rounded-md sm:text-black sm:bg-[#fff] sm:px-4 sm:py-4 lg:py-3;
}

select {
  @apply w-1/2 rounded-md text-black bg-[#fff] px-4 py-4 sm:w-full sm:rounded-md sm:text-black sm:bg-[#fff] sm:px-4 sm:py-4 lg:py-3;
}

select,
option,
input {
  @apply text-[#919191] sm:text-[#919191];
}

input:focus,
input:hover,
select:hover,
select:focus {
  @apply outline-1 outline outline-[#b1b1b1] sm:outline-1 sm:outline sm:outline-[#b1b1b1];
}

.text-error-text {
  @apply text-red-600 text-[12px] text-wrap mt-1 sm:text-red-600 sm:text-[12px] sm:text-nowrap sm:mt-0 lg:mt-0 lg:text-[10px];
}
