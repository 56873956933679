.post {
  @apply w-full bg-[#d8d8d8] no-underline text-[#3b3b3b] mb-[20px] ml-[25px] pb-[20px] h-auto sm:h-[425px] md:w-[200px];
}
.category {
  @apply text-red-600 text-base sm:text-xs pl-2 font-semibold pt-5;
}
.posttitle {
  @apply font-semibold pl-4 text-base sm:text-sm pt-3;
}
.postdata {
  @apply text-xs sm:text-[9px] px-1;
}
.postdesc {
  @apply pl-5 pr-5 my-[12px] flex items-start text-sm sm:text-xs h-auto;
}
.link {
  @apply pl-4 font-semibold text-base sm:text-xs p-1 bg-[#d8d8d8] mt-2 rounded-md text-[#3b3b3b];
}

.link:hover {
  @apply text-red-600;
}
