.container {
  @apply flex flex-row justify-center items-center border-t-3 border-solid border-[#d63744] w-full h-[100px];
}

.blogbutton {
  -webkit-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
  box-shadow: 5px 5px 7px -5px rgba(66, 68, 90, 1);
  @apply text-base p-4 font-semibold uppercase no-underline cursor-pointer border-0 rounded-lg bg-[#e0e0e0] duration-400 w-1/2;
}

.buttomBarButton {
  -webkit-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
  box-shadow: 5px 5px 7px -5px rgba(66, 68, 90, 1);
  @apply flex items-center justify-center font-semibold uppercase no-underline cursor-pointer p-[10px] text-xs border-0 rounded-lg bg-[#e0e0e0] duration-400 w-auto h-9;
}
